<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Nome"
              v-model="form.name"
              class="required"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-radio-group v-model="form.receipt" column>
              <v-radio
                label="Receber a parte do paciente ao realizar o procedimento"
                value="on-schedule"
              ></v-radio>
              <v-radio
                label="Receber todo o valor posteriormente"
                value="posteriorly"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              hide-details
              label="Desconto"
              v-model="form.discount"
              type="number"
              append-icon="mdi-percent"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Operação realizada com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";

export default {
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    form: {
      receipt: "on-schedule",
    },
    successDialog: false,
    actionAfterOperation: null,
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.name) errors.push("O campo NOME é obrigatório");

      if (!this.form.receipt)
        errors.push(
          "Informe a meneira como irá receber ao utilizar este convênio!"
        );

      if (this.form.receipt === "on-schedule" && !this.form.discount)
        errors.push("Informe o desconto!");

      return errors;
    },
    save(actionAfterOperation) {
      // console.log(this.form);

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/agreements`, this.form);

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(
          `/agreements/${this.$route.params.id}`,
          this.form
        );

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadAgreementData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/agreements/${this.$route.params.id}`
        );

        this.setAgreementData(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },
    setAgreementData(data) {
      const cpData = { ...data };

      // console.log(cpData);

      this.form = cpData;
    },
    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") this.successDialog = false;
    },
    clearForm() {
      this.form = {};
    },
  },
  created() {
    if (this.mod === "update") this.loadAgreementData();
  },
};
</script>

<style></style>
